"use strict";

/**
 * Navigation menu.
 */

const navigationOpenToggle = document.querySelector(".header__toggle");

const navigationCloseToggle = document.querySelector(".navigation__close");

const navigation = document.querySelector(".navigation");
const body = document.querySelector("body");

navigationOpenToggle.addEventListener("click", () => {
  navigation.classList.add("navigation--open");
  navigationOpenToggle.classList.add("navigation__close--hidden");
  body.classList.add("lock");

  bodyScrollLock.disableBodyScroll(navigation);
});

navigationCloseToggle.addEventListener("click", event => {
  event.preventDefault();
  navigation.classList.remove("navigation--open");
  body.classList.remove("lock");
  navigationOpenToggle.classList.remove("navigation__close--hidden");
  bodyScrollLock.enableBodyScroll(navigation);
});

$(".staffing-slider").slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 1000
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000
      }
    }
  ]
});

/**
 * Hero slider.
 */

$(".slider__inner").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  fade: true,
  cssEase: "linear",
  speed: 5000
});

// $(".staff__role").slick({
//   infinite: true,
//   slidesToShow: 1,
//   arrows: false,
//   dots: false,
//   autoplay: true,
//   autoplaySpeed: 1000
// });

// Slick event.
$(".slider__inner").on(
  "beforeChange",
  (event, slick, currentSlide, nextSlide) => {
    const nextSlideIndex = nextSlide;

    setActiveSector(nextSlideIndex);
  }
);

/**
 * Set the active sector on the hero banner.
 *
 * @param { number } nextSlideIndex
 */

function setActiveSector(nextSlideIndex) {
  const classes = {
    privateHouseholds: "sector--private-households",
    privateYachts: "sector--private-yachts",
    privateJets: "sector--private-jets",
    familyOffices: "sector--family-offices"
  };

  let nextActiveSector;

  switch (nextSlideIndex) {
    case 0:
      nextActiveSector = classes.privateHouseholds;
      break;
    case 1:
      nextActiveSector = classes.privateYachts;
      break;
    case 2:
      nextActiveSector = classes.privateJets;
      break;
    case 3:
      nextActiveSector = classes.familyOffices;
      break;
    default:
      nextActiveSector = classes.privateHouseholds;
      break;
  }

  const sectors = document.querySelectorAll(".sector--upper");

  sectors.forEach(sector => {
    sector.classList.remove("sector--active");
  });

  const activeSector = document.querySelector(`.${nextActiveSector}`);

  activeSector.classList.add("sector--active");
}

/**
 * Home testimonials slider.
 */

$(".testimonials-slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 10000,
  prevArrow: $(".slick-custom-arrow--prev"),
  nextArrow: $(".slick-custom-arrow--next")
});

/**
 * Home feed sliders.
 */

$(".latest-vacs-slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 7500
});

$(".interview-tips-slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000
});

$(".image-slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dots: true,
  fade: true,
  cssEase: "linear",
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 3500
});

/**
 * Home lower values slider.
 */

$(".principals__slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 7500
});

// Slick event.
$(".principals__slider").on(
  "beforeChange",
  (event, slick, currentSlide, nextSlide) => {
    const nextSlideIndex = nextSlide;

    setActiveSector2(nextSlideIndex);
  }
);

/**
 * Set the active sector on the hero banner.
 *
 * @param { number } nextSlideIndex
 */

function setActiveSector2(nextSlideIndex) {
  const classes = {
    integrity: "value--integrity",
    honesty: "value--honesty",
    transparency: "value--transparency"
  };

  let nextActiveSector;

  switch (nextSlideIndex) {
    case 0:
      nextActiveSector = classes.integrity;
      break;
    case 1:
      nextActiveSector = classes.honesty;
      break;
    case 2:
      nextActiveSector = classes.transparency;
      break;
    default:
      nextActiveSector = classes.privateHouseholds;
      break;
  }

  const sectors = document.querySelectorAll(".value");

  sectors.forEach(sector => {
    sector.classList.remove("value--active");
  });

  const activeSector = document.querySelector(`.${nextActiveSector}`);

  activeSector.classList.add("value--active");
}

/**
 * Clocks.
 */

function londonTime() {
  const date = new Date();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes.toString().length == 1) {
    minutes = `0${minutes}`;
  }

  const element = document.querySelector(".time--london");

  if (element) {
    element.textContent = `${hours}:${minutes}`;
  }
}

function zurichTime() {
  const date = new Date();

  date.setHours(date.getHours() + 1);
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes.toString().length == 1) {
    minutes = `0${minutes}`;
  }

  const element = document.querySelector(".time--zurich");

  if (element) {
    element.textContent = `${hours}:${minutes}`;
  }
}

function updateTimes() {
  londonTime();
  zurichTime();
}

updateTimes();

setInterval(updateTimes, 60000);

/**
 * Prevent times from linking to top of page.
 */

const times = document.querySelectorAll(".no-href");

if (times) {
  times.forEach(time => {
    time.addEventListener("click", event => {
      event.preventDefault();
    });
  });
}

/**
 * WOW animations.
 */

new WOW().init();

window.addEventListener("scroll", e => {
  const url = window.location.href;
  const page = url.split("/")[3]; // Ignore http prefix.

  if (page == "thank-you") {
    return;
  }
  if ($(window).scrollTop() <= 50) {
    $(".wow").removeClass("animated");
    $(".wow").removeAttr("style");
    new WOW().init();
  }
});

/**
 * Testimonials Slider.
 */

$(".testimonials-about__slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 7500,
  prevArrow: $(".slick-custom-arrow--prev"),
  nextArrow: $(".slick-custom-arrow--next")
});

/**
 * FAQs
 */

$(".faq__question").on("click", function() {
  if (
    $(this)
      .parent()
      .find(".faq__answer")
      .is(":visible")
  ) {
    $(this)
      .parent()
      .find(".faq__answer")
      .slideUp();
    $(this)
      .parent()
      .find(".faq__toggle")
      .toggleClass("faq__toggle--open");
  } else {
    // $(".faq__answer").slideUp();
    $(this)
      .parent()
      .find(".faq__answer")
      .slideToggle();
    // $(".faq__toggle").removeClass("faq__toggle--open");
    $(this)
      .parent()
      .find(".faq__toggle")
      .toggleClass("faq__toggle--open");
  }
});
// FAQ tabs.
$(".faq-candidate").on("click", e => {
  e.preventDefault();
  // $(this).addClass("primary-button--active");
  // $(".faq-employer").removeClass("primary-button--active");
  $(".faqs--candidate").fadeIn();
  $(".faqs--employer").hide();
});
$(".faq-employer").on("click", e => {
  e.preventDefault();
  // $(this).addClass("primary-button--active");
  // $(".faq-candidate").removeClass("primary-button--active");
  $(".faqs--employer").fadeIn();
  $(".faqs--candidate").hide();
});

/**
 * Available Candidates Slider.
 */

$(".available-candidates__slider").slick({
  infinite: true,
  slidesToShow: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 7500,
  prevArrow: $(".slick-custom-arrow--prev"),
  nextArrow: $(".slick-custom-arrow--next")
});

/**
 * Counters.
 */

$.fn.isInViewport = function() {
  let elementTop = $(this).offset().top;
  let elementBottom = elementTop + $(this).outerHeight();

  let viewportTop = $(window).scrollTop();
  let viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

let running1 = false;
let running2 = false;
let running3 = false;
let running4 = false;

$(window).scroll(() => {
  if (document.querySelector(".counter__count--years")) {
    if ($(".counter__count--years").isInViewport()) {
      if (running1 === false) {
        new Counter(".counter__count--years");
        running1 = true;
      }
    }
  }

  if (document.querySelector(".counter__count--recruiting")) {
    if ($(".counter__count--recruiting").isInViewport()) {
      if (running2 === false) {
        new Counter(".counter__count--recruiting");
        running2 = true;
      }
    }
  }

  if (document.querySelector(".counter__count--stringent")) {
    if ($(".counter__count--stringent").isInViewport()) {
      if (running3 === false) {
        new Counter(".counter__count--stringent");
        running3 = true;
      }
    }
  }

  if (document.querySelector(".counter__count--placement")) {
    if ($(".counter__count--placement").isInViewport()) {
      if (running4 === false) {
        new Counter(".counter__count--placement");
        running4 = true;
      }
    }
  }
});

/**
 * Forms.
 *
 */

const forms = document.querySelectorAll(".form");

const filters = document.querySelector(".filters");

const inputs = document.querySelectorAll(".filter__input");

const submitButtons = document.querySelectorAll(".form__submit");

const contactForms = document.querySelector(".contact");

if (contactForms) {
  submitButtons.forEach(button => {
    button.disabled = true;
  });

  contactForms.addEventListener("click", event => {
    if (event.target.classList.contains("agree")) {
      if (event.target.checked) {
        const form = event.target.parentElement.parentElement.parentElement;

        const submitButton = form.querySelector(".form__submit");

        submitButton.disabled = false;
      } else {
        const form = event.target.parentElement.parentElement.parentElement;

        const submitButton = form.querySelector(".form__submit");

        submitButton.disabled = true;
      }
    }
  });

  filters.addEventListener("change", event => {
    if (event.target.classList.contains("filter__input")) {
      const targetForm = event.target.getAttribute("data-form");

      inputs.forEach(input => {
        input.checked = false;
      });

      event.target.checked = true;

      forms.forEach(form => {
        form.style.display = "none";
      });

      document.querySelector(`.form--${targetForm}`).style.display = "block";
    }
  });
}

// const sidebar = document.querySelector(".vacancies__aside");

// sidebar.addEventListener("click", event => {
//   console.log("Click event triggered.");
//   if (!event.target.classList.contains("filter__name")) {
//     console.log("Returned out.");
//     return;
//   }

//   console.log(`Event Target: ${event.target}`);

//   const checkbox = event.target.closest(".filter__input");

//   console.log(checkbox);

//   if (checkbox.checked) {
//     checkbox.checked = false;
//     console.log("Checked = false.");
//   } else {
//     checkbox.checked = true;
//     console.log("Checked = true.");
//   }
// });

// Staffing slider.

// const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000
// });

$(".melanie-read-more").on("click", event => {
  event.preventDefault();
  $(".melanie-read-more-text").slideToggle();
  if ($(".melanie-read-more").text() == "Read more...") {
    $(".melanie-read-more").text("Read less...");
  } else {
    $(".melanie-read-more").text("Read more...");
  }
});

const vacanciesFilterToggle = document.querySelector(
  ".vacancies-filter-toggle"
);

const vacanciesSidebar = document.querySelector(".vacancies__aside");

if (vacanciesSidebar) {
  vacanciesFilterToggle.addEventListener("click", event => {
    event.preventDefault();
    vacanciesSidebar.classList.toggle("vacancies__aside--active");
  });
}

// $("video").each(function() {
//   this.play();
// });

// Parallax videos.

// const backgroundVideo1 = new BackgroundVideo(".video__video", {
//   src: [ "/site/assets/files/1/media_io_butler.mp4" ]
// });

$(".sidebar__title").on("click", function() {
  $(this)
    .siblings(".filters")
    .slideToggle();
});

// $(".filters").on("click", function() {
//   $(this)
//     .siblings(".filters--indent")
//     .slideToggle();
// });

$(() => {
  if (document.querySelector(".video__bar-2")) {
    $(window).scroll(() => {
      let scroll = $(window).scrollTop(); // how many pixels you've scrolled
      let os = $(".video__bar-2").offset().top; // pixels to the top of div1
      let ht = $(".video__bar-2").height(); // height of div1 in pixels
      // if you've scrolled further than the top of div1 plus it's height
      // change the color. either by adding a class or setting a css property

      if (scroll > os + ht) {
        $(".why-use-us-video").css("z-index", "-1");
        $(".video__video").css("z-index", "-2");
      } else {
        $(".why-use-us-video").css("z-index", "-2");
        $(".video__video").css("z-index", "-1");
      }
    });
  }
});

/**
 * Search ahead.
 */
// Search result list DOM element.
const resultsList = document.querySelector(".results-list");

/**
 * Display search matches.
 *
 * @param { array } matches.
 */

const display = matches => {
  resultsList.classList.add("active");

  const markup = matches.map(match => {
    return `<li><a href="#" class="result-link">${match.title}</a></li>`;
  });

  resultsList.innerHTML = markup.join("");
};

/**
 * Hide results list.
 */

const hideResults = () => {
  resultsList.classList.remove("active");
};

/**
 * Return matches based on a search term.
 * @param { string } term
 */

const search = async term => {
  // If there's no search term, return out.
  if (term.length === 0) {
    hideResults();
    return;
  }
  // JSON file URL.
  const url = "https://luxuryassets.co.uk/site/assets/data/search.json";

  // Fetch the contents of the JSON file.
  const response = await fetch(url);

  // Parse the result.
  const roles = await response.json();

  // Filter through the matches and run a regex match on each role.
  const matches = roles.filter(role => {
    const regex = new RegExp(`^${term}`, "gi");

    // Return matching roles.
    return role.title.match(regex);
  });

  if (matches.length === 0) {
    return;
  }

  display(matches);
};

// Search input DOM element.
const input = document.querySelector(".vacancies-search__input");

// Bind an event to search on type.
if (input) {
  input.addEventListener("input", () => search(input.value));
}

if (resultsList) {
  resultsList.addEventListener("click", event => {
    event.preventDefault();
    if (event.target.classList.contains("result-link")) {
      input.value = event.target.innerHTML;
      hideResults();
    }
  });
}
